<template>
    <div v-if="value">
        <div class="model-box">
            <div class="title">社区访问用户数据情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="visit_column" :dataList="value.communityInfoList"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="title">社区注册用户数据情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="registered_column" :dataList="value.communityInfoList"></ElemTable>
            </div>
        </div>
        <!-- <div class="model-box">
            <div class="title">“基层自治组织动员体系”建设推广情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="promotion_column" :dataList="value.communityInfoList"></ElemTable>
            </div>
        </div> -->
        <div class="model-box">
            <div class="title">社区信息发布数</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="message_column" :dataList="value.communityInfoList"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="title">社区接受服务人次</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="serve_column" :dataList="value.communityInfoList"></ElemTable>
            </div>
        </div>
        <div class="model-box">
            <div class="title">社区参与活动人次</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="activity_column" :dataList="value.communityInfoList"></ElemTable>
            </div>
        </div>
        <!-- <div class="model-box">
            <div class="title">空白栏目情况</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="blank_column" :dataList="value.communityInfoList"></ElemTable>
            </div>
        </div> -->
        <div class="model-box">
            <div class="title">示范社区空白栏目清单</div>
            <div class="table-box">
                <ElemTable :isIndex="true" :expand="expand" color="#21c065" :columns="blank_column_inventory" :dataList="blank_column_data"></ElemTable>
            </div>
        </div>
    </div>
</template>

<script>
import ElemTable from "../../communitymobilization/components/ElemTable.vue"

export default {
    components: { ElemTable },

    data() {
        return {
            // 空白栏目数据
            blank_column_data: null,
            // 访问用户
            visit_column: [
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计访问人数",
                    key: "statRegister",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增访问人数",
                    key: "registerOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增访问人数",
                    key: "addRegister",
                    highlight: true,
                    width: 150,
                },
            ],
            // 注册用户
            registered_column: [
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计注册人数",
                    key: "userRegister",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增注册人数",
                    key: "userRegisterOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增注册人数",
                    key: "addUserRegister",
                    highlight: true,
                    width: 150,
                },
            ],
            // 推广情况
            // promotion_column: [
            //     {
            //         title: "社区",
            //         key: "orgName",
            //         width: 200,
            //     },
            //     {
            //         title: "现入驻人数",
            //         key: "onlineHomeNum",
            //         highlight: true,
            //         sort: "desc",
            //         width: 150,
            //     },
            //     {
            //         title: "本期新入驻人数",
            //         key: "addOnlineHomeNum",
            //         highlight: true,
            //         width: 150,
            //     },
            // ],
            // 信息发布
            message_column: [
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计发布数",
                    key: "statOrgPublishNum",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增发布数",
                    key: "orgPublishNumOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增发布数",
                    key: "addOrgPublishNum",
                    highlight: true,
                    width: 150,
                },
            ],
            // 服务人次
            serve_column: [
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计接受服务人次",
                    key: "statViewNum",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增接受服务人次",
                    key: "viewOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增接受服务人次",
                    key: "addViewNum",
                    highlight: true,
                    width: 150,
                },
            ],
            // 参与活动人次
            activity_column: [
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "累计参与活动人次",
                    key: "statJoinNum",
                    highlight: true,
                    sort: "desc",
                    width: 150,
                },
                {
                    title: "本年新增参与活动人次",
                    key: "joinOfYear",
                    highlight: true,
                    width: 150,
                },
                {
                    title: "本期新增参与活动人次",
                    key: "addJoinNum",
                    highlight: true,
                    width: 150,
                },
            ],
            // 空白栏目
            blank_column: [
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "空白栏目数",
                    key: "blankColumnNum",
                    highlight: true,
                    sort: "desc",
                    width: 200,
                },
            ],
            // 空白栏目清单
            blank_column_inventory: [
                {
                    title: "社区",
                    key: "orgName",
                    width: 200,
                },
                {
                    title: "空白栏目清单",
                    highlight: true,
                    sort: (a, b) => {
                        if (/^<div.*>无<\/div>/.test(b)) return -1
                    },
                    width: 400,
                    render(h, v) {
                        return `<div style="color:${v.row.blankColumns?.length > 0 ? "red" : ""}">` + (v.row.blankColumns?.map(item => `<p>${item}</p>`).join("") || "无") + `</div>`
                    },
                },
            ],
        }
    },

    props: {
        value: Object,
        expand: {
            type: Boolean,
            default: false,
        },
    },

    watch: {
        value: {
            handler(v) {
                this.blank_column_data = v.communityInfoList?.filter(item => item.onlineStatus == 1)
            },
            immediate: true,
        },
    },
}
</script>

<style></style>
